<template>
  <div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="content">
          <div class="image-box">
            <img
              class="image"
              :src="item.imageUrl"
              @click="previewImg(index)"
            />
            <div class="tip">{{item.id}}</div>
          </div>

          <div class="name">
            {{item.coinKindName}}
          </div>
        </div>
        <div class="footer">
          <a-popconfirm
              title="确定要删除此项么？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handleRemove(item.id)"
          >
            <a>删除</a>
          </a-popconfirm>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    list: {
      default: [],
    },
  },
  components: {
  },
  methods: {
    /** 删除 */
    handleRemove(id) {
      this.axios("/dq_admin/duibanCoinBadcase/delById", {
        params: { id: id },
      }).then((res) => {
        this.$emit("deleteSuccess");
        this.$message.success("删除成功");
      });
    },
    // 预览
    previewImg(index) {
      const list = this.list.map((el) => {
        return {img_url: el.imageUrl}
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      margin-top: 10px;
      .image-box {
        position: relative;
        .image {
          width: 300px;
          height: 300px;
          border-radius: 4px;
        }
        .check-box {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 50px;
          height: 50px;
          .checked {
            position: absolute;
            width: 35px;
            height: 35px;
            bottom: 0;
            right: 0;
            label {
              span {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80px;
          height: 20px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
      }

      .name,
      .score {
        text-align: center;
        margin-top: 2px;
      }
    }
    .footer {
      margin-top: 2px;
      a {
        color: red;
        margin-right: 10px;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
      .move-btn {
        color: #1890ff;
      }
    }
  }
}
</style>