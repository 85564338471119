
<template>
  <div class="content">
    <div class="top">
      <!--   选择来源   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="sourceTypeId"
          placeholder="请选择来源"
          :dropdownMenuStyle="{'max-height': '500px'}"
          @change="handleChangeSourceType($event)"
      >
        <a-select-option
            v-for="item of sourceTypeList"
            :key="item.id"
            :value="item.id"
        >{{ item.value }}</a-select-option>
      </a-select>

      <a-button
          @click="search"
          size="small"
      >搜索</a-button>
    </div>
    <div class="table-content">
      <BadCaseList
          :list="badCaseList"
          @deleteSuccess="deleteSuccess"
      ></BadCaseList>
      <div class="pagination">
        <a-pagination
            :pageSize.sync="pagination.pageSize"
            :current="pagination.current"
            :total="pagination.total"
            @change="changePage"
        />

      </div>
    </div>
  </div>
</template>
<script>
import BadCaseList from "@/views/cmsPage/versionManage/duibanCoinBadcase/BadCaseList";

export default {
  components: {
    BadCaseList,
  },
  data() {
    return {
      badCaseList: [],
      sourceTypeId: undefined,
      sourceTypeList: [
        {value: '没检测到钱币', id: 0},
        {value: '币种正面没检测到文字', id: 1},
        {value: '裁剪错误', id: 10},
        {value: '裁剪边界值问题', id: 20},
        {value: '纹路斑点', id: 30},
        {value: '塑壳反光', id: 40},
        {value: '水渍反光', id: 50},
        {value: '正面检测错误', id: 60},
        {value: '背面检测错误', id: 70},
        {value: '盒子币检测错误', id: 80},
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 60,
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /** 选择来源 */
    handleChangeSourceType(e) {
      this.sourceTypeId = e
      this.getList()
    },
    deleteSuccess() {
      this.getList()
    },

    /** 获取列表数据 */
    async getList() {
      const params = {
        badcaseType: this.sourceTypeId ,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
    }
      const res = await this.axios("/dq_admin/duibanCoinBadcase/listData", {
        params: params
      });
      if (res.status != 200) return;
      this.badCaseList = res.data.records;
      this.pagination.total = res.data.total;
    },
    changePage(page) {
      this.pagination.current = page;
      this.getList();
    },
    search() {
      this.pagination.current = 1
      this.pagination.pageSize = 60
      this.getList()
    }
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    width: 100px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 25px;
    outline-style: none;
    cursor: pointer;
  }
}
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.bgf {
  background-color: #fff !important;
  border: 1px solid #000c17 !important;
  color: #000c17 !important;
  margin-left: 20px !important;
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
