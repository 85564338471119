var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "list" },
      _vm._l(_vm.list, function(item, index) {
        return _c("div", { key: index, staticClass: "item" }, [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "image-box" }, [
              _c("img", {
                staticClass: "image",
                attrs: { src: item.imageUrl },
                on: {
                  click: function($event) {
                    return _vm.previewImg(index)
                  }
                }
              }),
              _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(item.id))])
            ]),
            _c("div", { staticClass: "name" }, [
              _vm._v(" " + _vm._s(item.coinKindName) + " ")
            ])
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "a-popconfirm",
                {
                  attrs: {
                    title: "确定要删除此项么？",
                    "ok-text": "确认",
                    "cancel-text": "取消"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleRemove(item.id)
                    }
                  }
                },
                [_c("a", [_vm._v("删除")])]
              )
            ],
            1
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }